<template>
  <fb-page>
    <!-- 头部插槽 -->
    <fb-header slot="header" :title="title" fixed>
      <fb-button
        slot="left"
        icon="back"
        @click="backToNative()"
        size="small"
        type="primary"
      >
        返回
      </fb-button>
      <div slot="right">
        <div class="header-menu">
          <div class="icon" @click="onSearch">
            <van-icon name="search" />
          </div>
          <div class="icon" @click="onAdd">
            <van-icon name="plus" />
          </div>
        </div>
      </div>
    </fb-header>
    <join-organization v-if="!this.organizationId" />
    <van-popup v-model="searchVisible" position="top" get-container="body">
      <van-field
        left-icon="search"
        v-model="keyword"
        placeholder="检索案件名称、当事人名字"
        @input="searchInput"
        ref="inputsearch"
      />
    </van-popup>
    <div class="oa-approval">
      <filter-dropdown
        :filters="
          this.filterKV && this.filterKV.filtersStore
            ? this.filterKV.filtersStore
            : filters
        "
        @select="handleFilterSelect"
        @dateChange="handleFilterDateChange"
      />
      <!-- 案件列表 -->
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :finished="!isRequest"
          :finished-text="finishedText"
          @load="onLoad"
        >
          <div class="oa-case-list">
            <div
              class="oa-case-info"
              v-for="(item, index) in caseList"
              :key="index"
              @click="jump(item, index)"
            >
              <div class="oa-case-header">
                <div class="oa-case-title" v-if="type === '0'">立案申请</div>
                <div class="oa-case-title" v-if="type === '1'">结案申请</div>
                <div class="oa-case-userInfo">
                  <img :src="item.avatarUrl" alt="" />
                  <div>{{ item.nickname }}</div>
                </div>
                <div class="oa-case-status">
                  {{ caseStatusNameFmt(item.progress) }}
                </div>
              </div>
              <div class="oa-case-center">
                <div>{{ item.caseName }}</div>
              </div>
              <div class="oa-case-bottom">
                <div class="oa-case-time">申请时间：{{ item.useTime }}</div>
                <div class="oa-case-long">{{ item.useTimeText }}</div>
                <div class="oa-case-btn">
                  <button
                    v-for="(btnItem, btnIndex) in item.btnList"
                    :key="btnIndex"
                    @click.stop="btnClick(btnItem, item, index)"
                  >
                    {{ btnItem }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
  </fb-page>
</template>

<script>
import goBackMixin from '@mixins/goBack'
import FilterDropdown from '../../components/filterDropdown'
import { mapState, mapActions, mapMutations } from 'vuex'
import JoinOrganization from '@/components/join-organization'

export default {
  name: 'oa-close-case',
  mixins: [goBackMixin],
  components: {
    FilterDropdown,
    JoinOrganization
  },
  data() {
    return {
      title: '案件管理',
      finishedText: '没有更多了',
      type: '0', // 0立案审批   1结案审批
      searchVisible: false, // 搜索弹框
      filters: [
        {
          label: '立案审批',
          id: 'type',
          twoClick: false,
          options: [
            {
              text: '立案审批',
              value: '0'
            },
            {
              text: '结案审批',
              value: '1'
            }
          ]
        },
        {
          label: '诉讼案件',
          id: 'causeType',
          options: [
            {
              text: '诉讼案件',
              value: 'LITIGATION'
            },
            {
              text: '非诉讼案件',
              value: 'NON_LITIGATION'
            }
          ]
        },
        {
          label: '待审核',
          id: 'progress',
          noSelect: '状态',
          options: [
            {
              text: '未提交',
              value: 'UNSUBMENT'
            },
            {
              text: '待审核',
              value: 'UNAUDITED'
            },
            {
              text: '审核通过',
              value: 'APPROVED'
            },
            {
              text: '审核不通过',
              value: 'CERTIFIED_FAILED'
            }
          ]
        },
        {
          label: '我申请的',
          id: 'userType',
          options: [
            {
              text: '我申请的',
              value: 'APPLICANT'
            },
            {
              text: '我审批的',
              value: 'APPROVER'
            },
            {
              text: '抄送我的',
              value: 'RECIPIENT'
            }
          ]
        }
        // {
        //   label: '创建时间',
        //   id: 'date',
        //   options: []
        // }
      ],
      caseList: [], // 案件列表
      keyword: '',
      size: 10, // 每页条数
      page: 0, // 页码
      userType: 'APPLICANT',
      progress: 'UNAUDITED', // 筛选状态
      causeType: 'LITIGATION', // 诉讼案件或者非诉讼案件
      startTime: '', // 开始时间
      endTime: '', // 结束时间
      isRequest: true, // 是否可以继续请求
      loading: true, // 是否处于加载状态
      refreshing: false // 是否处于下拉刷新状态
    }
  },
  computed: {
    ...mapState('user', ['organizationId', 'userInfo']),
    ...mapState('oa', ['oaType', 'filterKV'])
  },
  methods: {
    ...mapActions('user', ['getOrganizationId']),
    ...mapMutations('oa', ['setOaType', 'setFilters']),
    // 搜索
    onSearch() {
      this.searchVisible = true
      setTimeout(() => {
        this.$refs.inputsearch.focus()
      }, 100)
    },
    // 添加
    onAdd() {
      if (this.type === '0') {
        this.setFiltersInPage()
        this.$router.push({
          name: 'createcase',
          query: {
            isBackCaseManager: 1
          }
        })
      } else {
        this.setFiltersInPage()
        this.$router.push({
          name: 'oa-caseInfo',
          query: {
            caseId: '',
            isAdd: 0 // 0表示新增
          }
        })
      }
    },
    // #筛选器# 选择处理
    handleFilterSelect(payload) {
      console.log('handleFilterSelect', payload)
      switch (payload.category.id) {
        case 'userType':
          this.userType = payload.option.value
          this.filtersStore = this.filters.map(item => {
            if (item.id === payload.category.id) {
              item.label = payload.option.text
            }
            return item
          })
          break
        case 'type':
          this.type = payload.option.value
          this.filtersStore = this.filters.map(item => {
            if (item.id === payload.category.id) {
              item.label = payload.option.text
            }
            return item
          })
          console.log(this.filtersStore)
          break
        case 'causeType':
          this.causeType = payload.option.value
          this.filtersStore = this.filters.map(item => {
            if (item.id === payload.category.id) {
              item.label = payload.option.text
            }
            return item
          })
          break
        case 'progress':
          if (this.progress === payload.option.value) {
            this.progress = ''
          } else {
            this.progress = payload.option.value
          }
          this.filtersStore = this.filters.map(item => {
            if (item.id === payload.category.id) {
              item.label = payload.option.text
            }
            return item
          })
          break
      }
      this.caseList = []
      this.page = 0
      this.isRequest = true
      this.getCaseList()
    },
    /* #筛选器-日期选择器# 改变处理
     * @param payload date类型
     */
    handleFilterDateChange(payload) {
      this.caseList = []
      this.startTime = payload[0]
      this.endTime = payload[1]
      this.page = 0
      this.isRequest = true
      this.getCaseList()
    },

    // 点击列表跳转事件
    jump(item, index) {
      if (this.type === '0') {
        this.setFiltersInPage()
        this.$router.push({
          name: 'oa-createCaseInfo',
          query: {
            caseId: item.caseId,
            commonId: item.id,
            // case: JSON.stringify(item),
            isAdd: 1
          }
        })
      } else {
        this.setFiltersInPage()
        this.$router.push({
          name: 'oa-caseInfo',
          query: {
            caseId: item.caseId,
            commonId: item.id,
            isAdd: 1
          }
        })
      }
    },

    // 获取组织id
    async getId() {
      await this.getOrganizationId()
      if (!this.organizationId) {
        return this.$bus.$emit('join-org', { show: true })
      }
    },

    // 获取案件列表
    getCaseList() {
      this.loading = true
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true
      })
      const params = {
        size: this.size,
        page: this.page,
        // keyword: this.keyword,
        progress: this.progress,
        userId: this.userInfo.id,
        userType: this.userType
        // startTime: this.startTime,
        // endTime: this.endTime
      }
      let url = ''
      if (this.type === '0') {
        url = `${this.$base}/management/legal/case/record`
        params.caseType = this.causeType
        this.$axios
          .post(url, params)
          .then(res => {
            console.log(res)
            if (res.data.data) {
              if (res.data.data.total === 0) {
                // 无数据显示缺省页面
                this.caseList = res.data.data.records
                this.defaultPage = 1
                this.isRequest = false
              } else {
                if (res.data.data.records.length > 0) {
                  for (var i = 0; i < res.data.data.records.length; i++) {
                    // 获取申请人昵称和头像
                    let avatarUrl = ''
                    let nickname = ''
                    res.data.data.records[i].approveRecordUsers.map(
                      (item, i) => {
                        if (item.type === 'APPLICANT') {
                          avatarUrl = item.nickName
                          nickname = item.nickName
                        }
                      }
                    )
                    res.data.data.records[i].avatarUrl = avatarUrl
                    res.data.data.records[i].nickname = nickname
                    // 格式化申请时间
                    const tempTime = res.data.data.records[i].createTime || ''
                    if (tempTime) {
                      res.data.data.records[i].useTime = this.timestampToTime(
                        tempTime
                      )
                      res.data.data.records[i].useTimeText = this.getDateDiff(
                        tempTime
                      )
                    }
                    if (this.type === '0') {
                      // 处理按钮逻辑
                      switch (res.data.data.records[i].progress) {
                        case 'UNSUBMENT':
                          res.data.data.records[i].btnList = ['申请', '删除']
                          break
                        case 'UNAUDITED':
                          for (
                            var j = 0;
                            j <
                            res.data.data.records[i].approveRecordUsers.length;
                            j++
                          ) {
                            if (
                              res.data.data.records[i].approveRecordUsers[j]
                                .type === 'APPROVER'
                            ) {
                              if (
                                res.data.data.records[i].approveRecordUsers[j]
                                  .userId === this.userInfo.id
                              ) {
                                res.data.data.records[i].btnList = [
                                  '批准',
                                  '驳回'
                                ]
                                break
                              } else {
                                res.data.data.records[i].btnList = []
                              }
                            }
                          }
                          break
                        case 'APPROVED':
                          res.data.data.records[i].btnList = []
                          break
                        case 'CERTIFIED_FAILED':
                          res.data.data.records[i].btnList = ['申请', '删除']
                          break
                      }
                    }
                  }
                  this.caseList = this.caseList.concat(res.data.data.records)
                }
                if (this.caseList.length >= res.data.data.total) {
                  this.isRequest = false
                } else {
                  this.isRequest = true
                }
              }
            } else {
              this.isRequest = false
            }
            this.loading = false
            this.refreshing = false
            this.$toast.clear()
          })
          .catch(err => {
            console.log(err)
            this.loading = false
            this.isRequest = false
            this.refreshing = false
            this.finishedText = '暂无数据'
            this.$toast.clear()
          })
      } else {
        url = `${this.$base}/management/case/end/record/${
          this.organizationId
        }/organization`
        params.causeType = this.causeType
        // delete params.userType
        this.$axios
          .get(url, {
            params
          })
          .then(res => {
            console.log(res)
            if (res.data.data) {
              if (res.data.data.totalElements === 0) {
                // 无数据显示缺省页面
                this.caseList = res.data.data.content
                this.defaultPage = 1
                this.isRequest = false
              } else {
                if (res.data.data.content.length > 0) {
                  for (var i = 0; i < res.data.data.content.length; i++) {
                    // 获取申请人昵称和头像
                    let avatarUrl = ''
                    let nickname = ''
                    res.data.data.content[i].approveRecordUsers.map(
                      (item, i) => {
                        if (item.type === 'APPLICANT') {
                          avatarUrl = item.user.avatarUrl
                          nickname = item.user.nickName
                        }
                      }
                    )
                    res.data.data.content[i].avatarUrl = avatarUrl
                    res.data.data.content[i].nickname = nickname
                    // 格式化申请时间
                    const tempTime =
                      this.type === '0'
                        ? parseInt(res.data.data.content[i].acceptCaseTime)
                        : res.data.data.content[i].createTime
                    res.data.data.content[i].useTime = this.timestampToTime(
                      tempTime
                    )
                    res.data.data.content[i].useTimeText = this.getDateDiff(
                      tempTime
                    )
                    if (this.type === '0') {
                      // 处理按钮逻辑
                      switch (res.data.data.content[i].progress) {
                        case 'UNSUBMENT':
                          res.data.data.content[i].btnList = ['申请', '删除']
                          break
                        case 'UNAUDITED':
                          for (
                            var j = 0;
                            j <
                            res.data.data.content[i].approveRecordUsers.length;
                            j++
                          ) {
                            if (
                              res.data.data.content[i].approveRecordUsers[j]
                                .type === 'APPROVER'
                            ) {
                              if (
                                res.data.data.content[i].approveRecordUsers[j]
                                  .user.id === this.userInfo.id
                              ) {
                                res.data.data.content[i].btnList = [
                                  '批准',
                                  '驳回'
                                ]
                                break
                              } else {
                                res.data.data.content[i].btnList = []
                              }
                            }
                          }
                          break
                        case 'APPROVED':
                          res.data.data.content[i].btnList = []
                          break
                        case 'CERTIFIED_FAILED':
                          res.data.data.content[i].btnList = ['申请', '删除']
                          break
                      }
                    } else {
                      // 处理按钮逻辑
                      switch (res.data.data.content[i].progress) {
                        // case 'UNSUBMENT':
                        //   res.data.data.content[i]['btnList'] = ['申请', '删除']
                        //   break
                        case 'UNAUDITED':
                          for (
                            var k = 0;
                            k <
                            res.data.data.content[i].approveRecordUsers.length;
                            k++
                          ) {
                            if (
                              res.data.data.content[i].approveRecordUsers[k]
                                .type === 'APPROVER'
                            ) {
                              if (
                                res.data.data.content[i].approveRecordUsers[k]
                                  .user.id === this.userInfo.id
                              ) {
                                res.data.data.content[i].btnList = [
                                  '批准',
                                  '驳回'
                                ]
                                break
                              } else {
                                res.data.data.content[i].btnList = []
                              }
                            }
                          }
                          break
                        case 'APPROVED':
                          res.data.data.content[i].btnList = []
                          break
                        // case 'CERTIFIED_FAILED':
                        //   res.data.data.content[i]['btnList'] = ['申请', '删除']
                        //   break
                      }
                    }
                  }
                  this.caseList = this.caseList.concat(res.data.data.content)
                }
                if (this.page + 1 >= res.data.data.totalPages) {
                  this.isRequest = false
                } else {
                  this.isRequest = true
                }
              }
            } else {
              this.isRequest = false
            }
            this.loading = false
            this.refreshing = false
            this.$toast.clear()
          })
          .catch(() => {
            this.loading = false
            this.isRequest = false
            this.refreshing = false
            this.finishedText = '暂无数据'
            this.$toast.clear()
          })
      }
    },

    // 案件状态格式化
    caseStatusNameFmt(code) {
      const dict = {
        UNSUBMENT: '未提交',
        UNAUDITED: '待审核',
        APPROVED: '审核通过',
        CERTIFIED_FAILED: '审核不通过'
      }
      return dict[code]
    },

    // 时间戳转换成时间
    timestampToTime(cjsj) {
      var date = new Date(cjsj) // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + '-'
      var M =
        (date.getMonth() + 1 < 10
          ? '0' + (date.getMonth() + 1)
          : date.getMonth() + 1) + '-'
      var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
      var h =
        ' ' +
        (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) +
        ':'
      var m =
        date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
      return Y + M + D + h + m
    },

    // 申请时间格式化
    getDateDiff(dateTimeStamp) {
      var result = ''
      var minute = 1000 * 60
      var hour = minute * 60
      var day = hour * 24
      var now = new Date().getTime()
      var diffValue = now - dateTimeStamp
      const tag = diffValue > 0 ? '前' : '后'
      diffValue = Math.abs(diffValue)
      var dayC = diffValue / day
      var hourC = diffValue / hour
      var minC = diffValue / minute
      if (dayC >= 1) {
        result = '' + parseInt(dayC) + '天' + tag
      } else if (hourC >= 1) {
        result = '' + parseInt(hourC) + '小时' + tag
      } else {
        result = '' + parseInt(minC) + '分钟' + tag
      }
      return result
    },

    // 搜索功能
    searchInput() {
      this.caseList = []
      this.page = 0
      this.isRequest = true
      this.getCaseList()
    },

    // 上拉加载事件
    onLoad() {
      this.page++
      this.loading = true
      this.getCaseList()
    },

    // 下拉刷新
    onRefresh() {
      this.caseList = []
      this.page = 0
      this.isRequest = true
      this.getCaseList()
    },

    /**
     * 按钮点击事件
     * name 操作名称
     * item 数据内容
     * index 第几条数据
     */
    btnClick(name, item, index) {
      if (this.type === '0') {
        if (name === '批准' || name === '驳回' || name === '申请') {
          let progress
          if (name === '批准') {
            progress = 'APPROVED'
          } else if (name === '驳回') {
            progress = 'CERTIFIED_FAILED'
          } else if (name === '申请') {
            progress = 'UNAUDITED'
          }
          item.progress = progress
          const tempUser = []
          var approveRecordUsers = item.approveRecordUsers
          for (let i = 0; i < approveRecordUsers.length; i++) {
            if (approveRecordUsers[i].type === 'APPLICANT') {
              tempUser.push(approveRecordUsers[i])
              break
            }
          }
          tempUser.push({
            type: 'APPROVER',
            user: {
              id: this.userInfo.id
            }
          })
          for (let i = 0; i < approveRecordUsers.length; i++) {
            if (approveRecordUsers[i].type === 'RECIPIENT') {
              tempUser.push(approveRecordUsers[i])
              break
            }
          }
          item.approveRecordUsers = tempUser
          this.$axios
            .put(
              `${this.$base}/management/legal/case/record/${
                item.caseId
              }/progress`,
              item
            )
            .then(res => {
              const { code } = res.data
              if (code === 200) {
                this.$toast.success('操作成功')
                setTimeout(() => {
                  this.onRefresh()
                }, 1000)
              }
            })
        } else if (name === '删除') {
          this.$dialog
            .confirm({
              title: '提示',
              message: '删除不可恢复！确定删除吗？'
            })
            .then(() => {
              this.$axios
                .post(
                  `${this.$base}/management/legal/case/record/delete/${
                    item.caseId
                  }?userId=${this.userInfo.id}`
                )
                .then(res => {
                  const { code } = res.data
                  if (code === 200) {
                    this.$toast.success('删除成功')
                    setTimeout(() => {
                      this.caseList.splice(index, 1)
                    }, 1000)
                  }
                })
            })
        }
      } else {
        if (name === '批准' || name === '驳回') {
          item.progress = name === '批准' ? 'APPROVED' : 'CERTIFIED_FAILED'
          const tempUser = []
          var approveUsers = item.approveRecordUsers
          for (let i = 0; i < approveUsers.length; i++) {
            if (approveUsers[i].type === 'APPLICANT') {
              tempUser.push(approveUsers[i])
              break
            }
          }
          tempUser.push({
            type: 'APPROVER',
            user: {
              id: this.userInfo.id
            }
          })
          for (let i = 0; i < approveUsers.length; i++) {
            if (approveUsers[i].type === 'RECIPIENT') {
              tempUser.push(approveUsers[i])
              break
            }
          }
          item.approveRecordUsers = tempUser
          this.$axios
            .post(
              `${this.$base}/management/case/end/record/${this.userInfo.id}`,
              item
            )
            .then(res => {
              const { code, message } = res.data
              if (code === 200) {
                this.$toast.success(message)
                setTimeout(() => {
                  this.onRefresh()
                }, 1000)
              }
            })
        }
      }
    },
    // 设置查询条件
    setFiltersInPage() {
      this.setFilters({
        causeType: this.causeType,
        progress: this.progress,
        userType: this.userType,
        filtersStore: this.filtersStore
      })
    }
  },
  async created() {
    if (this.filterKV) {
      this.causeType = this.filterKV.causeType
      this.userType = this.filterKV.userType
      this.progress = this.filterKV.progress
    }
    console.log(this.filterKV)
    // 结案
    let type = 'close'
    if (this.oaType) {
      type = this.oaType
    } else {
      type = this.$route.query.type
    }
    if (type === 'close') {
      this.filters = [
        {
          label: '结案审批',
          id: 'type',
          twoClick: false,
          options: [
            {
              text: '立案审批',
              value: '0'
            },
            {
              text: '结案审批',
              value: '1'
            }
          ]
        },
        {
          label: '诉讼案件',
          id: 'causeType',
          options: [
            {
              text: '诉讼案件',
              value: 'LITIGATION'
            },
            {
              text: '非诉讼案件',
              value: 'NON_LITIGATION'
            }
          ]
        },
        {
          label: '待审核',
          id: 'progress',
          noSelect: '状态',
          options: [
            {
              text: '未提交',
              value: 'UNSUBMENT'
            },
            {
              text: '待审核',
              value: 'UNAUDITED'
            },
            {
              text: '审核通过',
              value: 'APPROVED'
            },
            {
              text: '审核不通过',
              value: 'CERTIFIED_FAILED'
            }
          ]
        },
        {
          label: '我申请的',
          id: 'userType',
          options: [
            {
              text: '我申请的',
              value: 'APPLICANT'
            },
            {
              text: '我审批的',
              value: 'APPROVER'
            },
            {
              text: '抄送我的',
              value: 'RECIPIENT'
            }
          ]
        }
      ]
      this.type = '1'
    } else {
      // 立案
      this.filters = [
        {
          label: '立案审批',
          id: 'type',
          twoClick: false,
          options: [
            {
              text: '立案审批',
              value: '0'
            },
            {
              text: '结案审批',
              value: '1'
            }
          ]
        },
        {
          label: '诉讼案件',
          id: 'causeType',
          options: [
            {
              text: '诉讼案件',
              value: 'LITIGATION'
            },
            {
              text: '非诉讼案件',
              value: 'NON_LITIGATION'
            }
          ]
        },
        {
          label: '待审核',
          id: 'progress',
          noSelect: '状态',
          options: [
            {
              text: '未提交',
              value: 'UNSUBMENT'
            },
            {
              text: '待审核',
              value: 'UNAUDITED'
            },
            {
              text: '审核通过',
              value: 'APPROVED'
            },
            {
              text: '审核不通过',
              value: 'CERTIFIED_FAILED'
            }
          ]
        },
        {
          label: '我申请的',
          id: 'userType',
          options: [
            {
              text: '我申请的',
              value: 'APPLICANT'
            },
            {
              text: '我审批的',
              value: 'APPROVER'
            },
            {
              text: '抄送我的',
              value: 'RECIPIENT'
            }
          ]
        }
      ]
      this.type = '0'
    }
  },
  async mounted() {
    await this.getId()
    this.getCaseList()
  },
  destroyed() {
    this.setOaType('')
  }
}
</script>

<style lang="stylus">
.oa-approval
  position relative
.oa-case-list
  padding 0 10px
  box-sizing border-box
  & .oa-case-info
    border-radius 5px
    border 1px solid rgba(204, 204, 204, 1)
    width 100%
    height auto
    padding 10px 0
    box-sizing border-box
    margin-top 10px
    & .oa-case-header
      display flex
      flex-direction row
      align-items center
      justify-content space-between
      padding 0 10px
      box-sizing border-box
    & .oa-case-title
      font-size 14px
      color #666666
    & .oa-case-userInfo
      font-size 14px
      display flex
      flex-direction row
      align-items center
      img
        width 24px
        height 24px
        margin-right 5px
        border-radius 12px
    & .oa-case-status
      font-size 16px
    & .oa-case-center
      font-size 16px
      font-weight bold
      box-sizing border-box
      padding 10px 15px
      border-bottom 1px solid rgba(204, 204, 204, 1)
      div
        display -webkit-box
        -webkit-box-orient vertical
        -webkit-line-clamp 2
        overflow hidden
    & .oa-case-bottom
      display flex
      flex-direction row
      align-items center
      box-sizing border-box
      padding 10px 10px 0 10px
      & .oa-case-time
        font-size 12px
        color #999999
        margin-right 10px
      & .oa-case-btn
        display flex
        flex-direction row
        align-items center
        justify-content flex-end
        flex 1
        button
          width 55px
          height 30px
          border-radius 5px
          border 1px solid rgba(0, 121, 254, 1)
          background none
          color #0079FE
          font-size 14px
</style>
